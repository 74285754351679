import React from "react"

import Layout from "../components/layout-static"
import Seo from "../components/seo"


const Page = () => {
  const metadata = {
    title: "Bicycle Accident",
    heroH1: "Bicycle Accident",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <p className="mb-8">February 25, 2015 by Frank Bartlett, Sr.</p>
            <p className="mb-8">
              Q: From Bristol, CT. Do I have a case for a bicycle accident
              trying to avoid getting hit by a car?
            </p>
            <p className="mb-8">
              I was riding down a hill on the sidewalk and saw a car encroach
              into a driveway on the same sidewalk. I attempted to stop but
              notice that the car stopped and to my knowledge I figured that the
              car was letting me pass. I continued. But the car commenced up the
              driveway. Trying to avoid getting hit by the car, I quickly swayed
              to the side and hit a curb then went flying. I was not able to get
              the driver’s information, but the driver did call the cops and the
              ambulance took me to the hospital. The officer took my information
              as well as the hospital. Now I am stuck with a medical bills and
              regular doctor’s visit for physical therapy for a dislocated disc,
              pinch nerve, and a possible injury to my left rotator cuff.
            </p>

            <p className="mb-8">
              A: Based on the facts you presented, the driver of the car was
              likely negligent, which would allow you to pursue a claim against
              him if you are able to locate him. If you are unable to locate the
              driver, you may still be able to pursue a claim against your own
              insurance policy for uninsured motorist benefits. In any event,
              you should speak with a local personal injury attorney who can
              assist you with this process.
            </p>
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default Page
